import dynamic from 'next/dynamic';
import {useContext, useEffect} from 'react';
import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import Image from 'next/image';
import IconLaguna from 'aac-components/components/Icons/AAC/IconLaguna';
import IconAdcare from 'aac-components/components/Icons/AAC/IconAdcare';
import IconDesertHope from 'aac-components/components/Icons/AAC/IconDesertHope';
import IconGreenhouse from 'aac-components/components/Icons/AAC/IconGreenhouse';
import IconOxford from 'aac-components/components/Icons/AAC/IconOxford';
import IconRiverOaks from 'aac-components/components/Icons/AAC/IconRiverOaks';
import IconRecoveryFirst from 'aac-components/components/Icons/AAC/IconRecoveryFirst';
import {BREAKPOINT} from '../../lib/styles';
import React from 'react';
import styled from 'styled-components';
import GeoTargetMapWrapper from '../GeoTargetMapWrapper';
import IconChat from 'aac-components/components/Icons/Communication/IconChat';
import IconMobile from 'aac-components/components/Icons/Media/IconMobile';
import IconShield from 'aac-components/components/Icons/Basic/IconShield';
import ABTest from '../ABTest';
import AppContext from '../AppContext';
import {useMentalHealthVob} from '../../lib/utils';

const VobAktifyCta = dynamic(() => import('aac-components/components/VobAktifyCta'));

const translation = {
    title: {
        en: 'Facilities that specialize in addiction treatment',
        es: 'Centros que se especializan en el tratamiento de adicciones',
    },
    subtitle: {
        en: 'Click on a pin to learn more information on a specific facility location.',
        es: 'Haga clic en un pin para obtener más información sobre la ubicación de una instalación específica.',
    },
    link: {
        en: 'View All Facilities',
        es: 'Ver todas las instalaciones',
    },
};

const VobAktifyCtaStyles = styled.div`
    #ga__vob-aktify-cta {
        border: none;
        padding: 0;
    }
    #ga__vob-aktify-cta > div:nth-child(1) {
        display: none;
    }
`;

const FacilityCta = (props) => {
    const {lang = 'en'} = props;
    useEffect(() => {
        window?.lazyLoadInstance?.update();
    }, []);

    const {pageProps: {data: {tags = []} = {}} = {}} = useContext(AppContext);

    return (
        <div className="cta">
            <p className="cta__header-title">
                Find Drug & Alcohol Rehab Treatment Near You
            </p>
            <div className="cta__header-img">
                <Image
                    src="https://admin.americanaddictioncenters.org/aac-common-bucket/aac-lockup-horizontal-color-v2.svg"
                    fill
                    sizes="300px"
                    alt="American Addiction Centers Logo"
                    style={{objectFit: 'contain'}}
                />
            </div>
            <div className="cta__title text-lg bold">{translation?.title?.[lang]}</div>
            <div id="facility-cta">
                <div className="cta__copy text-xs">{translation?.subtitle?.[lang]}</div>
                <a
                    href="/treatment-centers"
                    className="cta__link"
                    title={translation?.link?.[lang]}>
                    <span>{translation?.link?.[lang]}</span>
                    <IconArrowShort fill="var(--tertiary)" rotate="-90" />
                </a>

                <div className="cta__map">
                    <GeoTargetMapWrapper />
                </div>
                <div className="cta__links">
                    <ul>
                        <li>
                            <a
                                href="/treatment-centers/laguna-treatment-center"
                                title="Orange County, California">
                                <IconLaguna width="24px" height="24px" />
                                <div>Orange County, California</div>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/treatment-centers/desert-hope"
                                title="Las Vegas, Nevada">
                                <IconDesertHope width="24px" height="24px" />
                                <div>Las Vegas, Nevada</div>
                            </a>
                        </li>
                        <li>
                            <a href="/treatment-centers/greenhouse" title="Dallas, Texas">
                                <IconGreenhouse width="24px" height="24px" />
                                <div>Dallas, Texas</div>
                            </a>
                        </li>
                        <li>
                            <a href="/treatment-centers/oxford" title="Etta, Mississippi">
                                <IconOxford width="24px" height="24px" />
                                <div>Etta, Mississippi</div>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/treatment-centers/river-oaks"
                                title="Riverview, Florida">
                                <IconRiverOaks width="24px" height="24px" />
                                <div>Riverview, Florida</div>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/treatment-centers/recovery-first-hollywood"
                                title="Hollywood, Florida">
                                <IconRecoveryFirst width="24px" height="24px" />
                                <div>Hollywood, Florida</div>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/treatment-centers/adcare-hospital"
                                title="Worcester, Massachusetts">
                                <IconAdcare width="24px" height="24px" />
                                <div>Worcester, Massachusetts</div>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/treatment-centers/adcare-rhode-island"
                                title="North Kingstown, Greenville and Warwick Rhode Island">
                                <IconAdcare width="24px" height="24px" />
                                <div>
                                    North Kingstown, Greenville and Warwick, Rhode Island
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="facility-cta__options-container">
                <div className="facility-cta__options-title">
                    <b>Take the first step towards recovery and get help today.</b>{' '}
                    American Addiction Centers provides addiction and mental health care
                    at various locations nationwide.
                </div>
                <div className="facility-cta__options-links">
                    <div
                        title="Check Insurance Coverage"
                        onClick={() => window?.AAC?.openSlideInSsvob()}>
                        <IconShield fill="var(--interactive)" />{' '}
                        <span>Check Insurance Coverage</span>
                    </div>
                    <div
                        title="Sign Up for Text Support"
                        onClick={() => window?.AAC?.openSlideInSms()}>
                        <IconMobile fill="var(--interactive)" />{' '}
                        <span>Sign Up for Text Support</span>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    a {
                        cursor: pointer;
                    }
                    .cta h2 {
                        text-align: center;
                        text-transform: capitalize;
                        margin: 0 0 30px 0;
                    }
                    .cta {
                        text-align: center;
                        margin: 30px auto 0 auto;
                        background: #fff;
                        border-radius: 8px;
                        margin: 24px 0px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta {
                            margin: 60px 0;
                        }
                    }
                    .cta__header-title {
                        font-family: var(--title-font);
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 40px;
                    }
                    .cta__header-img {
                        position: relative;
                        min-height: 45px;
                        margin-bottom: 24px;
                    }
                    .cta__title {
                        margin-bottom: 16px;
                    }
                    .cta__copy {
                        margin-bottom: 16px;
                    }
                    .cta__link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 5px;
                        margin: 0 auto 30px auto;
                        max-width: fit-content;
                    }
                    .cta__map {
                        max-width: 500px;
                        margin: 0 auto 40px auto;
                        background: #fff;
                    }

                    ul {
                        list-style: none;
                    }
                    ul a {
                        color: var(--interactive);
                        margin-bottom: 5px;
                        display: grid;
                        grid-template-columns: 20px auto;
                        justify-content: flex-start;
                        align-items: flex-start;
                        grid-gap: 10px;
                        text-align: left;
                    }
                    img {
                        width: 644px;
                        max-width: 100%;
                        height: auto;
                    }
                    .cta__links ul {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                        padding: 0;
                        margin: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cta__links ul {
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 8px;
                            justify-content: center;
                        }
                    }
                    .cta__vob-aktify {
                        padding: 0 16px;
                    }
                    .facility-cta__options-container {
                        border-top: 4px solid var(--interactive-100);
                        border-bottom: 4px solid var(--interactive-100);
                        padding: 24px 0;
                        text-align: left;
                        margin: 40px 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facility-cta__options-container {
                            padding: 24px;
                        }
                    }
                    .facility-cta__options-title {
                        margin-bottom: 16px;
                        line-height: 24px;
                    }
                    .facility-cta__options-links {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 16px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facility-cta__options-links {
                            grid-template-columns: auto auto;
                            grid-gap: 40px;
                            justify-content: flex-start;
                        }
                    }
                    .facility-cta__options-links > div {
                        display: flex;
                        align-items: center;
                        grid-gap: 8px;
                        color: var(--interactive);
                        cursor: pointer;
                    }
                `}
            </style>
        </div>
    );
};
export default FacilityCta;
